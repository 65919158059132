/* .header-main {
    margin: 4% 0;
} */

.header-main.header-wrapper {
    transition: ease all .5s;
    /* top: -100%; */
}

.header-main.header-wrapper.affix {
    position: fixed;
}

.header-main.header-wrapper.affix {
    top: 0;
    left: 0;
    margin: 0;
    right: 0;
    z-index: 999;
    background: rgba(255, 255, 255, .9);
    border-bottom: 1px solid #666;
}

.header-main .head-image-section {
    display: flex;
    justify-content: space-between;
}

.header-main .displayNone {
    display: none !important;
}

.header-main .LogoScrollWidth {
    /* width: 70%; */
    display: none;
}

.header-main .header-menu {
    margin: 30px 0;
}

.header-main .header-menu.Lessmargin {
    margin: 0 0 0;
}

.header-main .header-menu .navbar {

    position: relative;
    min-height: 50px;
}

.header-main .header-menu .hovernav:hover>.dropdown-menu {
    display: block;
}

.header-main .header-menu .navbar-list .dropdown-menu {
    font-family: 'MuseoSans100', sans-serif;
    border-radius: 0;
    padding: 0;
    margin: 0;
    border: 0;
    position: absolute;
    top: 135%;
    left: 0;
    z-index: 1000;
    display: none;
    min-width: 200px;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    background-clip: padding-box;
    font-weight: 600;

}

.header-main .header-menu .navbar-nav {
    flex-direction: row;
    row-gap: 30px;
}

.header-main .header-menu .navbar-list>li>a:hover {
    background-color: #eee;
    border-left: 0.5px solid #666;
    color: #272b2d !important;
}

.header-main .after-dropdown::after {
    display: none;
}

.header-main .header-menu .navbar-list>li>a {
    border: 1px solid #666;
    border-left: 1px solid #666;
    border-right: 1px solid #666;
    margin-left: -1px;
    padding: 13.5px 40px;
    color: #4b5256;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    font-family: MuseoSans700;
}

.header-main .header-menu .navbar-list>li:first-child a {
    border-left: 1px solid #666;
}

.header-main .header-menu .navbar-list .dropdown-menu>li>a {
    border: 1px solid #666;
    margin-bottom: -1px;
    margin-left: -1px;
    display: block;
    padding: 15px 15px;
    font-weight: 600;
    color: #4b5256;
}

.header-menu .navbar-nav .dropdown-menu>li>a:hover {
    color: #272b2d !important;
    background-color: #eee;
    text-decoration: none;
}

/* .header-main .header-menu .navbar-list .dropdown-menu>li>a:hover {
    background-color: #eee;
} */

.csc-space-before-55 {
    margin-top: 55px !important;
}

/* #mainnavbar .dropdown-toggle:active,
#mainnavbar .dropdown-toggle:focus {
    background-color: #9ec4de;
} */

/* #mainnavbar .dropdown-toggle:hover,
#mainnavbar .dropdown-menu li:hover {
    background-color: #eee;
} */

/* #mainnavbar .dropdown-menu .active.current.active-link {
    background-color: #9ec4de;
} */

.level-1 li.list-group-item:hover,
.level-1 li.list-group-item a:hover {
    background-color: #eee;
    color: #272b2d !important;
}

/* #mainnavbar li.dropdown.hovernav.active.current.active-link a.dropdown-toggle {
    background-color: #9ec4de;
} */
#mainnavbar li.dropdown.hovernav.active.current.active-link a.dropdown-toggle {
    background-color: #9ec4de;
}

#mainnavbar .dropdown-menu .active.current.active-link {
    background-color: #9ec4de;
}

/* /* //// */

.header-wrapper.affix .toolbar {
    margin-bottom: -1px;
    background: rgba(238, 238, 238, .7);
}

.toolbar {
    background: #eee;
    margin-bottom: 50px;
    min-height: 40px;
}

.container,
.container-fluid {
    padding-left: 15px;
    padding-right: 15px;
}

.container,
.container-fluid {
    margin-right: auto;
    margin-left: auto;
}

.toolbar .toolbar-wrapper {
    position: relative;
}

.header-wrapper.affix .sticky-logo {
    max-width: 120px;
    display: inline-block;
    margin-top: 6px;
}

.lang-navbar {
    display: inline-block;
    position: absolute;
    right: 245px;
}

.container,
.container-fluid {
    padding-left: 15px;
    padding-right: 15px;
}

.container,
.container-fluid {
    margin-right: auto;
    margin-left: auto;
}

.img-responsive {
    display: block;
    height: auto;
}

.lang-navbar {
    display: inline-block;
    position: absolute;
    right: 245px;
}

.langnav-header {
    overflow: visible;
    min-height: 40px;
    padding: 11px 15px 0 0;
    cursor: pointer;
    position: relative;
}

.langnav-header p {
    float: left;
    margin-bottom: 0;
    margin-top: -1px;
    text-align: justify;

}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.langnav {
    clear: both;
    padding: 0;
    list-style: none;
    display: none;
    z-index: 9999;
    position: relative;
}

.langnav li.active a {
    background: #9ec4de;
}

.langnav>li>.disabled,
.langnav>li>a {
    border: 1px solid #4b5256;
    padding: 10px 15px 8px;
    display: block;
    margin-bottom: -1px;
    background: #fff;
}

#searchbox {
    position: absolute;
    right: 0;
    top: 0;
}

fieldset {
    margin: 0;
    min-width: 0;
}

fieldset,
legend {
    padding: 0;
    border: 0;
}

#searchbox .tx-indexedsearch-form {
    position: relative;
}

.input-group {
    display: table;
    border-collapse: separate;

}

#searchbox #tx-indexedsearch-searchbox-sword2 {
    width: 220px;
    height: 30px;
    margin-top: 4px;
    border: 0;
    /* padding: 10px 12px 6px; */
    border-radius: 0;
}

#searchbox .input-group-btn {
    display: none;
}

.input-group,
.input-group-btn,
.input-group-btn>.btn {
    position: relative;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer;
}

.btn-primary {
    color: #fff;
    background-color: #9ec4de;
    border-color: #8bb8d8;
}

.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.btn,
.dropdown-header,
.dropdown-menu>li>a {
    line-height: 1.65;
    white-space: nowrap;
}

#searchbox .input-group-btn {
    display: none;
}

#searchbox i.fa-search {
    position: absolute;
    z-index: 99;
    right: 9px;
    top: 10px;
    cursor: pointer;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.toolbar {
    background: #eee;
    margin-bottom: 50px;
    min-height: 40px;
}

header .logo {
    margin-bottom: 40px;
}

.img-responsive {
    display: block;
    height: auto;
}

.header-wrapper.affix .sticky-logo {
    max-width: 120px;
    display: inline-block;
    margin-top: 6px;
}

.header-wrapper.affix .claim,
.header-wrapper.affix .logo-bar,
.sticky-logo {
    display: none;
}

header .logo {
    margin-bottom: 40px;
}


.toolbar {
    background: #eee;
    margin-bottom: 50px;
    min-height: 40px;
}

.container,
.container-fluid {
    padding-left: 15px;
    padding-right: 15px;
}

.container,
.container-fluid {
    margin-right: auto;
    margin-left: auto;
}

#searchbox i.fa-search:before {
    font-size: 19px;
}

/* */
@media only screen and (max-width:768px) {
    .headerLogo img {
        width: 75%;
    }

    .img-responsive {
        display: none;
        height: auto;
    }

    html,
    body {
        margin-top: 0% !important;
    }

}



@media only screen and (max-width:1200px) {
    .header-main .header-menu .navbar-list>li>a {
        padding: 15px 7px;
    }

}

@media (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1170px !important;
    }


}

@media (min-width: 800px) {

    html,
    body {
        margin-top: 130px !important;
    }

    .header-main.header-wrapper.sticky-header {
        position: fixed;
    }

    .header-main.header-wrapper.sticky-header {
        top: 0;
        left: 0;
        margin: 0;
        right: 0;
        z-index: 999;
        background: rgba(255, 255, 255, .9);
    }

    /* .header-wrapper.sticky-header .toolbar {
        margin-bottom: -1px;
        background: rgba(238, 238, 238, .7);
    } */

    .header-wrapper.sticky-header .sticky-logo.sticky-logo-display {
        max-width: 120px;
        display: inline-block;
        margin-top: 6px;
    }



    /* .header-wrapper.sticky-header .claim,
    .header-wrapper.sticky-header .logo-bar,
    .sticky-logo {
        display: none;
    } */
}

/* .ice-blue-header{
    color: var(--iceblue) !important;
  font-size: 18px;
  font-weight: 700;
}
.toll-number{
    color: var(--textBlack) !important;
    font-size: 16px;
    font-weight: 700;
} */
.heading-text li {
    font-family: "MuseoSans300", sans-serif;
    font-size: 14px;
    margin-top: .5%;
    text-align: justify;
    color: var(--textBlack);
    line-height: 1.65;
}

.list-cell {
    /* position: relative; */
    padding-left: 20px;
}

.list-mark {
    position: relative;
    left: -5px;
}

#table-container table {
    border-collapse: collapse;
    width: 100%;
}


#table-container td {
    border: 1px solid black;
    padding: 8px;
    /* text-align: center; */
    font-size: 14px;
    font-family: 'MuseoSans100';
    font-weight: 600;
    text-align: left;
    padding-left: 18px;
    color: var(--textBlack);

}

#table-container\ ct-components td {
    border: 1px solid black;
    padding: 8px;
    text-align: center;
    font-size: 14px;
    font-family: 'MuseoSans100';
    font-weight: 600;
    /* text-align: left; */
    color: var(--textBlack);

}

#table-container th {
    border: 1px solid black;
    padding: 15px;
    text-align: center;
    font-size: 14px;
    /* font-family: 'MuseoSans100'; */
    font-weight: 600;
    color: #4b5256;
    background: #eee;
}

#table-container\ ct-components th {
    border: 1px solid black;
    padding: 15px;
    text-align: center;
    font-size: 14px;
    /* font-family: 'MuseoSans100'; */
    font-weight: 600;
    color: #4b5256;
    background-color: #d5e8f5;
}

#table-container-whiteGrey table {
    border-collapse: collapse;
    width: 100%;
}


#table-container-whiteGrey td {
    border: 1px solid black;
    padding: 8px;
    /* text-align: center; */
    font-size: 14px;
    font-family: 'MuseoSans100';
    font-weight: 600;
    text-align: left;
    padding-left: 18px;
    color: var(--textBlack);
}

#table-container-whiteGrey th {
    border: 1px solid black;
    padding: 15px;
    text-align: center;
    font-size: 14px;
    /* font-family: 'MuseoSans100'; */
    font-weight: 600;
    color: #4b5256;
    background: #eee;
}

#table-container-whiteGrey tbody tr:nth-child(even) {
    background-color: #ffffff;
}

#table-container-whiteGrey tbody tr:nth-child(odd) {
    background-color: #d5e8f5;
}

#table-container-milstone-project th,
#table-container-milstone-project td {
    border: 1px solid black;
    padding: 8px;
    font-size: 14px;
    font-family: 'MuseoSans100';
    font-weight: 600;
    color: var(--textBlack);

}

#table-container-milstone-project td {
    text-align: left;
    padding-left: 18px;

}

#table-container-milstone-project th {
    color: #4b5256;
    font-weight: 600;
    text-align: center;
    background: #eee;
}

#table-container-milstone-project table {
    border-collapse: collapse;
    width: 100%;
}

.table-units th {
    background-color: #eee !important;
}

.csc-space-before-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.img-center-inner {
    width: 100%;
    text-align: center;
}

#table table {
    border-collapse: collapse;
    border: 1px solid black;
    width: 100%;
    text-align: start;
}


#table th {
    border: 1px solid black;
    text-align: center;
    padding: 5px;
    font-size: 16px;
    /* font-family: 'MuseoSans100'; */
    font-weight: 600;
    color: #4b5256;
    background: #eee;
}

#table td {
    font-size: 14px;
    font-family: 'MuseoSans100';
    font-weight: 600;
    text-align: left;
    padding: 4px;
    padding-left: 18px;
    color: var(--textBlack);
}

.corporate-governance li {
    margin-top: .5%;
    text-align: justify;
}

.frst-typ {
    font-size: 18px;
    color: #4b5256;
    margin-bottom: 11.5px;
}

.scnd-typ {
    font-size: 18px;
    color: #4b5256;
    margin-bottom: 11.5px;
}

/*  */
.powermail_check_inner.alex {
    display: flex;
    align-items: baseline;
    gap: 15px;
    margin-top: .5%;
    font-size: 17px;
    color: var(--textBlack);
}

.subalsxm {
    font-weight: 700 !important;
    font-size: 22px !important;
    color: var(--textBlack);
}

.alexmsubli {
    margin-top: 4%;
    text-align: justify;
}

.alxmlbn {
    margin-top: 4%;
}

.mynmk ul li {
    margin-top: 0%;
    font-weight: 600;
    color: var(--textBlack);
    text-align: justify;
}

.pysctics {
    margin-top: 4%;
}

.pyshk {
    text-align: center;
}

.clpyshk {
    padding-top: 4%;
}

.bundle {
    margin-top: 4%;
}

.budloi {
    margin-top: 4%;
}

.budloi ul li {
    font-weight: 600;
    color: var(--textBlack);
    text-align: justify;
}

.powermail_check_outer ul {
    margin-bottom: .5%;
}

.about-landing-page {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 0.5rem;
}

.img-width-set {
    width: 250px;
    margin: auto;
}

.img-width-set-spray-Nzl {
    width: 50%;
    margin: auto;
}

/*  */
.HomepagetextTable-main .card-box-main {
    border: 1px solid var(--textBlack);
}

.HomepagetextTable-main .card-box-main:first-child {
    margin-left: 0;
}

.HomepagetextTable-main .text-card-box1 {
    background-image: url('../images/jpeg/txtcard1bgimg.jpg');
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
}

.HomepagetextTable-main .card-box-main {
    margin: 0 0 0 -1px;
}

.HomepagetextTable-main .card-box-main .text-section {
    padding: 15px 15px 10px;
}

.HomepagetextTable-main .card-box-main .text-section h3 {
    color: var(--textBlack);
    font-family: 'MuseoSansRounded900';
}

.HomepagetextTable-main .card-box-main .text-section p {
    font-size: 14px;
    color: var(--textBlack);
    font-family: 'MuseoSans100';
    font-weight: 600;
    text-align: justify;

}

.HomepagetextTable-main .text-card-box4 {
    background-image: url('../images/jpeg/txtcard2bgimg.jpg');
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
}

@media only screen and (max-width: 991px) {
    .card-box-main:first-child {
        margin-right: -1px;
    }

    .card-box-main:last-child {
        margin-left: -1px;
        margin-bottom: 0;
    }
}

@media only screen and (min-width: 992px) and (max-width:1200px) {
    .HomepagetextTable-main .card-box-main {
        height: 466px !important;
    }
}

@media only screen and (min-width: 576px) and (max-width:768px) {
    .HomepagetextTable-main .card-box-main {
        height: 488px !important;
    }
}

@media only screen and (max-width: 450px) {
    .HomepagetextTable-main .card-box-main {
        height: 396px !important;
    }
}
.footer-main .footer-hr {
    border-color: var(--textBlack);
    opacity: 1;
}

.footer-main .link-section-main {
    display: flex;
    align-items: center;
    gap: 25px;
}

.footer-main .link-section-main .footer-links {
    display: flex;
    gap: 5px;
    font-size: 14px;
    color: var(--textBlack);
    font-family: 'MuseoSans100';
    font-weight: 600;

}

.footer-main .social-icons {
    width: 25px;
}

.footer-main .copyright-text {
    text-align: end;
    font-size: 14px;
    color: var(--textBlack);
    font-family: 'MuseoSans100';
    font-weight: 600;

}
.error-main {
    text-align: center;
    margin-bottom: 10%;
}

.error-main .heading-text img {
    margin-bottom: 20px;

}

.error-main .heading-text h1 {
    font-size: 40px !important;
    font-family: 'MuseoSansRounded900', sans-serif;
    color: var(--textBlack);
}

.error-main .heading-text .sub-head {
    display: flex;
    align-items: center;
    margin: 30px auto;
}

.error-main .heading-text .sub-head a {
    color: var(--textBlack);
    text-decoration: underline !important;
}

.error-main .heading-text .sub-head a:hover {
    color: var(--hoverBlack);
}
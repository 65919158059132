.comingsoon-main .heading-text {
    text-align: center;
    padding: 5%;

}

.comingsoon-main .heading-text h1 {
    color: var(--textBlack);
    font-size: 40px !important;
    margin-bottom: 20px;

}

.comingsoon-main .heading-text h2 {
    font-size: 26px;
    color: var(--textBlack);
}

.comingsoon-main .heading-text p {
    margin: 10px;
    text-align: justify;

}

.comingsoon-main .heading-text a {
    color: var(--textBlack);
    text-decoration: underline !important;

}

.comingsoon-main .heading-text a:hover {
    color: var(--hoverBlack);
}
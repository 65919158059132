.ourmanagement-main .heading-text h1 {
    font-size: 40px !important;
    color: var(--textBlack);
    /* font-weight: 700; */
}

.ourmanagement-main .heading-text h2 {
    font-size: 26px;
}

.ourmanagement-main .heading-text p {
    color: var(--textBlack);
    text-align: justify;

}

.ourmanagement-main .heading-text li {
    font-family: "MuseoSans300", sans-serif;
    font-size: 14px;
    text-align: justify;
}

.accordion-body {
    position: relative;
}

@media only screen and (max-width: 768px) {
    .ourmanagementImg img {
        width: 100%;
        padding-bottom: 10px;
    }

}
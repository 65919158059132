.accordion-main {
    margin: 10px 0;
}

.accordion-main .accordion {
    border-radius: 0;
}

.accordion-main .accordion .accordion-item {
    border-radius: 0;
    border: 1px solid var(--textBlack);
}

.accordion-main .accordion .accordion-button:hover {
    color: var(--hoverBlack)
}

.accordion-main .accordion .accordion-button {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: var(--iceblue);
    padding: 15px 30px;
    font-size: 15px;
    color: var(--textBlack);
    font-family: 'MuseoSansRounded900', sans-serif;
}

.accordion-main .accordion .accordion-button:active {
    border: none;
}

.accordion-main .accordion .accordion-button:focus {
    border: 0;
    box-shadow: none;
}

.accordion-main .accordion .accordion-button::after {
    /* display: none; */
    content: none;

}

.accordion-main .accordion .accordion-button::before {
    font-family: FontAwesome;
    content: "\f0d7";
    display: inline-block;
    float: left;
    margin-top: -1px;
    width: 20px;
    position: absolute;
    left: 10px;
}
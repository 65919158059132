.firstHeader {
  font-size: 40px !important;
  color: var(--textBlack) !important;
}

/* .ice-blue {
  margin-top: 3%;
} */

.conatctMainkl {
  /* margin-top: 4%; */
}

.decelartion {
  margin-top: 2%;
}

label.dectxt {
  margin-top: 2%;
  font-size: 18px;
  color: var(--textBlack);
  padding-left: 10px;
  font-family: "MuseoSans300" !important;
  font-weight: 600;
  font-size: 14px;
  text-decoration: underline;
}

.form-group.conPage {
  /* width: 70%; */
  margin-top: 2%;

}

.form-group.conPage button {
  border-radius: 0;
  color: #fff;
  /* background-color: #9ec4de !important; */
  background-color: #388dc8 !important;
  border-color: #8bb8d8 !important;
  font-size: 14px;
}

.form-group.conPage button:hover {
  color: #fff;
  background-color: #78add1 !important;
  border-color: #5d9dc8 !important;

}

.form-control.inpConpg {
  margin-top: 2%;
  color: var(--textBlack) !important;
}

.content-country {
  max-height: 170px;
  overflow-y: auto;
  /* width: 30%; */
  margin-top: 3%;
  border: 1px solid;
  /* padding-left: 16px; */
  padding: 7px 10px 0px 15px;
}

.country-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.countryNamess {
  padding-left: 10px;
}

.liCnameandBox {
  margin-top: 2%;
}

.lableHtTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--iceblue) !important;
}

.companyContact {
  margin-top: 3%;
}

.personContact {
  margin-top: 3%;
}

.sendmsg {
  color: #fff !important;
  background-color: #9ec4de !important;
  border-color: #8bb8d8 !important;
}

.btn:first-child:active:focus-visible {
  box-shadow: var(--textBlack) !important;
}

.titlconm {
  color: var(--textBlack) !important;
  margin-bottom: 6%;
}

.contact-card {
  /* border: 1px solid; */
  padding: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.contact-cards {
  margin-top: 1.5rem;
}

.cardsadrs {
  margin-top: 1.5rem !important;
}

.adrsTitlj {
  color: var(--textBlack);
  font-size: 17px !important;
}

.subaDress {
  color: var(--textBlack);
  font-family: "MuseoSans100" !important;
  font-weight: 600;
  font-size: 14px !important;
}

.changesUp {
  margin-top: 4% !important;
}


.tx-powermail legend {
  border-bottom: 0 none;
  margin-bottom: 5px;
  padding-top: 20px;
}

fieldset.powermail_fieldset.powermail_fieldset_1 {
  margin-top: 5%;
}

fieldset.powermail_fieldset.powermail_fieldset_2 {
  margin-top: 5%;
}

fieldset.powermail_fieldset.powermail_fieldset_3 {
  margin-top: 5%;
}

/* .tx-powermail legend {
border-bottom: 0 none;
margin-bottom: 5px;
padding-top: 20px;
} */
legend.powermail_legend {
  border-bottom: 0 none;
  margin-bottom: 5px;
  /* padding-top: 20px; */
  color: #333;
  font-size: 21px;
  font-family: 'MuseoSans300', sans-serif;
}

.powermail_check_inner.powermail_check_inner_1 {
  margin-top: 3%;
  display: flex;
  align-items: baseline;

}

.css-13cymwt-control {
  color: var(--textBlack);
  font-weight: 100;
  border: 1px solid #4b5256 !important;
  border-radius: 0 !important;
  font-size: 14px;
}

.css-13cymwt-control:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.contact-form input::placeholder,
.contact-form textarea::placeholder,
.contact-form select::placeholder {
  color: var(--textBlack) !important;
  font-weight: 600;
  font-size: 15px;
}

.css-1dimb5e-singleValue {
  /* color: #ccc !important; */
  color: var(--textBlack) !important;
  font-family: 'MuseoSans100' !important;
  font-weight: 600;
  font-size: 15px;
}

.css-1jqq78o-placeholder {
  color: var(--textBlack) !important;
  font-family: 'MuseoSans100' !important;
  font-weight: 600;
  font-size: 15px;
}

.contact-form input:focus,
.contact-form input,
.contact-form textarea {
  color: var(--textBlack);
  font-weight: 600;
  border: 1px solid #4b5256;
  border-radius: 0;
  font-size: 15px;
}

.contact-form .form-group :focus {

  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.error-message {
  font-size: 14px;
  color: var(--color-red);
}

/* Create a custom toast container style */
.custom-toast-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 9999;
  pointer-events: none;
}

.custom-toast {
  pointer-events: all;
}

.Toastify__toast {
  height: 200px !important;
  width: 100% !important;
}

.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: block !important;
  align-items: center;
  text-align: center !important;
}

.Toastify__toast-icon {
  margin-inline-end: 10px;
  width: 50px !important;
  flex-shrink: 0;
  display: block;
  margin: 0 auto 10px auto !important;
}

.Toastify__close-button {
  display: none !important;
}

.toast-div p {
  color: #07BC0C;
  font-size: 18px !important;
  font-weight: 500;
}
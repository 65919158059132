.sidebar .well-sm {
    border-radius: 0;
    background: #fff;
    margin-bottom: -1px;
    position: relative;
    padding: 15px;
    border: 0;
    font-family: 'MuseoSans300' !important;
    font-size: 15px;
    box-shadow: 1px 0 0 0 #4b5256, 0 1px 0 0 #4b5256, 1px 1px 0 0 #4b5256, 1px 0 0 0 #4b5256 inset, 0 1px 0 0 #4b5256 inset;
}

.aboutus-sections-sub .well {
    min-height: 20px;
}

.sidebar h4 {
    color: #9ec4de;
    text-transform: uppercase;
    font-family: 'MuseoSansRounded900', sans-serif;
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 11.5px;
}

.aboutus-sections-sub .img-wrap {
    max-width: 100%;
}

.aboutus-sections-sub p {
    margin: 0 0 11.5px;
    text-align: justify;

}

.aboutus-sections-sub strong {
    font-weight: 700;
}

.sidebar ul li:before {
    content: "\f0da";
    font-family: FontAwesome;
    padding-right: 5px;
    margin-left: -10px;
    font-size: 14px;
    color: #4b5256;
}

.aboutus-sections-sub ul.uploads li.uploads-element {
    padding-left: 0;
    margin-left: 0;
}

.aboutus-sections-sub strong {
    color: #4b5256 !important;
}

.aboutus-sections-sub ul.uploads li.uploads-element span.uploads-fileSize {
    margin-left: 10px;
}

.aboutus-sections-sub .recentlypublished {

    border: 1px solid var(--textBlack);
    border-top: 0;
    padding: 20px 10px;
}


/* //fgj// */
.business-company h4 {
    color: #4b5256;
    font-size: 16px;
    text-transform: none;
    margin-bottom: 20px;
}

.business-company-person h4 {
    color: #4b5256;
    font-size: 16px;
    text-transform: none;
    margin-bottom: 10px !important;
}

ul.key-points-marker li {
    list-style-type: none;
    font-size: 14px;
    /* font-family: 'MuseoSans300'; */
    line-height: 1.65;
    font-family: 'MuseoSans300' !important;
    color: var(--textBlack);
}

.col-title {
    font-size: 15px;
    font-family: 'MuseoSans300';
    color: #4b5256;
}

.col-title .email a {
    font-family: "MuseoSans700" !important;
    font-weight: 400;
}

.col-title .email .emailid {
    font-size: 15px;
    font-family: 'MuseoSans300';
    color: #4b5256;
}

.alllabelselect,
.col-title {
    padding-left: 14px;
    width: 100%;
}

.businesscard,
.sidebar .dynContent .businesscard {
    margin-top: 25px;
}

#additional {
    font-weight: 400;
    font-family: 'MuseoSans700' !important;
    line-height: 1.65;
    color: #4b5256;
}

.sidebar #contactSelect .email:before,
.sidebar #contactSelect .mobile:before,
.sidebar .dynContent .email:before,
.sidebar .dynContent .mobile:before {
    content: "\f0da";
    font-family: FontAwesome;
    padding-right: 5px;
}

.sidebar .dynContent .email,
.sidebar .dynContent .mobile,
.sidebar .dynContent .person {
    font-family: "MuseoSans700";
    /* font-weight: 400; */
}

dd {
    margin-left: 0;
}

dd,
dt {
    line-height: 1.65;
}
.ice-blu{
    color: #9fc4de !important;
    font-size: 18px;
  }
  ice-blu {
    color: #9fc4de !important;
  }
  .aboutus-sections-sub strong {
    color: #4c5356 !important;
  }
    .ice-blu:hover{
    border-bottom: 1px solid #9fc4de;
      border-bottom-color: #4b5256 !important;
      color: #4c5356 !important;
  }
h1 {
    font-size: 26px !important;
    color: var(--textBlack);
}

h3 {
    font-size: 24px !important;
    margin-bottom: 11.5px !important;
    font-family: 'MuseoSansRounded900', sans-serif;
    font-weight: 400 !important;
    line-height: 1.1;
    color: inherit;
    text-transform: uppercase;
    color: #4b5256 !important;

}

h4 {
    font-size: 18px;
    margin-top: 11.5px;
    margin-bottom: 11.5px;
    font-family: 'MuseoSansRounded900';
    font-weight: 400;
    line-height: 1.1;
    color: inherit;

}

a {
    text-decoration: none !important;
    color: var(--textBlack) !important;
    font-family: 'MuseoSans300';
}

p {
    margin-bottom: 11.5px !important;
    font-family: 'MuseoSans300';
}



h1,
h2 {
    text-transform: uppercase;
    font-family: 'MuseoSansRounded900', sans-serif;


}

.ice-blue {
    color: var(--iceblue) !important;
    font-size: 26px;
    /* font-weight: 700; */
}

.lead {
    font-size: 17px !important;
    font-family: 'MuseoSans700', sans-serif;

}

.accordion-text {
    font-family: 'MuseoSans300', sans-serif;
    font-size: 14px;
    /* font-weight: 600; */
    text-align: left;
    line-height: 1.65;
    color: #4b5256;
    text-align: justify;
}


strong {
    font-family: 'MuseoSans700' !important;
    font-weight: 700;
}

.row {
    margin: 0 !important;
}

.flexslider1 .flex-control-nav {
    bottom: 10px
}

.flexslider1:hover .flex-direction-nav .flex-prev {
    left: 10px;
    opacity: 1;
}

.flexslider1:hover .flex-direction-nav .flex-next {
    right: 10px;
    opacity: 1;
}

.flexslider1 .flex-direction-nav a {
    height: 50px !important;
}

.flexslider1 .flex-control-paging li a {
    border: 2px solid #fff;
    background: 0 0;
    box-shadow: none !important;
}

.flexslider1 .flex-control-paging li a:hover {
    background-color: #fff;
}

.flexslider1 .flex-control-paging li a.flex-active {
    background-color: #fff;
}

.breadcrumbs-main {
    padding: 8px 10px;
    margin: 20px 0;
}

.breadcrumbs-main a {
    color: var(--textBlack);
    font-size: 14px;
}

.headtext-section p {
    font-size: 14px;
    line-height: 1.65;
    color: var(--textBlack);
    margin: 0 0 11.5px;
    font-family: "MuseoSans300", sans-serif;
    text-align: justify;
}

.heading-text h1 {
    font-size: 40px !important;
    color: var(--textBlack);
    /* font-weight: 700; */
}

.sub-heading h2 {
    font-size: 26px !important;
    color: var(--textBlack);
    /* font-weight: 700; */
}

.sub-heading .ice-blue h2 {
    font-size: 26px;
    /* font-weight: 700; */
}

.headtext-section .lead {
    font-size: 17px;
    font-weight: 300;
    margin-bottom: 20px !important;
    line-height: 1.4;
    font-family: 'MuseoSans700' !important;
}


.headtext-section a {
    color: var(--textBlack);
    text-decoration: underline !important;
}

.slick-dots {
    bottom: -18px !important;

}


.slick-dots li button:before {
    font-size: 12px !important;
}

.bannercarousel-main.mb-5 {
    width: 549px;
    min-height: 366px;
}

button.slick-arrow.slick-prev {
    width: 90%;
    height: 100px;
    background: url("../src/assets/images/svg/prev.svg") 7% 0 / 7% no-repeat !important;
    z-index: 9;
}

button.slick-arrow.slick-next {
    width: 90%;
    height: 100px;
    background: url("../src/assets/images/svg/next.svg") 93% 0 / 7% no-repeat !important;
    /* transition: background-position 0.3s ease; */
}

.bannercarousel-main-home.mb-5 button.slick-arrow.slick-prev {
    width: 50%;
    height: 50px;
    background: url("../src/assets/images/svg/prev.svg") 7% 0 / 7% no-repeat !important;
    z-index: 9;
}

.bannercarousel-main-home.mb-5 button.slick-arrow.slick-next {
    width: 50%;
    height: 50px;
    background: url("../src/assets/images/svg/next.svg") 93% 0 / 7% no-repeat !important;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: unset !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.productbox:focus .link,
.productbox:hover .link {
    text-decoration: none;
}

#contactSelect select,
.form-control {
    width: 100%;
    height: 37px;
    padding: 6px 12px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

#contactSelect select,
.form-control,
output {
    font-size: 14px;
    line-height: 1.65;
    color: #555;
    display: block;
    font-family: 'MuseoSans100' !important;
}

#contactSelect select:focus,
.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

hr {
    margin-top: 23px;
    margin-bottom: 23px;
    border-top: 1px solid #eee !important;
    opacity: 1 !important;
}


@media only screen and (max-width: 768px) {
    .bannercarousel-main.mb-5 {
        width: auto;
        min-height: auto;
    }

    .header-main .header-menu.Lessmargin {
        margin: 30px 0;
    }

    .references {
        /* margin: 0 auto;
        position: relative; */
        width: auto !important;
    }

    div#table-container\ ct-components {
        overflow-x: scroll;
    }

}

@media only screen and (max-width: 1100px) {
    div#table-container\ ct-components {
        overflow-x: scroll;
    }
}
.homepagetext-main .image-heading {
    display: flex;
    align-items: center;
    gap: 30px;
}



.homepagetext-main .image-heading h1 {
    color: var(--textBlack);
    font-size: 26px;
    font-family: "MuseoSansRounded900";
}

.homepagetext-main .text-content p {
    color: var(--textBlack);
    font-size: 17px;
    font-family: MuseoSans700;
    text-align: justify;

}

@media only screen and (max-width: 450px) {
    .homepagetext-main .image-heading {
        flex-direction: column;
    }

    .homepagetext-main .image-heading h1 {
        text-align: center;
    }
}
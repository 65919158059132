body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

@font-face {
  font-family: 'MuseoSans100';
  src: local('Museo'),
    url('/src/assets/fonts/MuseoSans-100.otf') format('opentype');
  /* src: url('/src/assets/fonts/Vision.otf') format('woff2'); */
  /* Add more src lines for different formats if needed */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans300';
  /* src: local('Museo'),
    url('/src/assets/fonts/MuseoSans-300.otf') format('opentype'); */
  src: url(/src/assets/fonts/MuseoSansCyrl_300-webfont.eot);
  src: url(/src/assets/fonts/MuseoSansCyrl_300-webfont_1.eot) format("embedded-opentype"), url(/src/assets/fonts/MuseoSansCyrl_300-webfont.woff2) format("woff2"), url(/src/assets/fonts/MuseoSansCyrl_300-webfont.woff) format("woff"), url(/src/assets/fonts/MuseoSansCyrl_300-webfont.ttf) format("truetype");
  /* src: url('/src/assets/fonts/Vision.otf') format('woff2'); */
  /* Add more src lines for different formats if needed */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans700';
  /* src: local('Museo'), */
  /* url('/src/assets/fonts/MuseoSans_700.otf') format('opentype'); */
  src: url(/src/assets/fonts/MuseoSansCyrl_700-webfont.eot);
  src: url(/src/assets/fonts/MuseoSansCyrl_700-webfont_1.eot) format("embedded-opentype"), url(/src/assets/fonts/MuseoSansCyrl_700-webfont.woff2) format("woff2"), url(/src/assets/fonts/MuseoSansCyrl_700-webfont.woff) format("woff"), url(/src/assets/fonts/MuseoSansCyrl_700-webfont.ttf) format("truetype");
  /* src: url('/src/assets/fonts/Vision.otf') format('woff2'); */
  /* Add more src lines for different formats if needed */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans900';
  /* src: local('Museo'), */
  /* url('/src/assets/fonts/MuseoSans_900.otf') format('opentype'); */
  src: url(/src/assets/fonts/MuseoSansCyrl_900-webfont.eot);
  src: url(/src/assets/fonts/MuseoSansCyrl_900-webfont_1.eot) format("embedded-opentype"), url(/src/assets/fonts/MuseoSansCyrl_900-webfont.woff2) format("woff2"), url(/src/assets/fonts/MuseoSansCyrl_900-webfont.woff) format("woff"), url(/src/assets/fonts/MuseoSansCyrl_900-webfont.ttf) format("truetype");
  /* src: url('/src/assets/fonts/Vision.otf') format('woff2'); */
  /* Add more src lines for different formats if needed */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSansRounded100';
  src: local('Museo'),
    url('/src/assets/fonts/MuseoSansRounded100.otf') format('opentype');
  /* src: url('/src/assets/fonts/Vision.otf') format('woff2'); */
  /* Add more src lines for different formats if needed */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSansRounded300';
  src: local('Museo'),
    url('/src/assets/fonts/MuseoSansRounded300.otf') format('opentype');
  /* src: url('/src/assets/fonts/Vision.otf') format('woff2'); */
  /* Add more src lines for different formats if needed */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSansRounded500';
  src: local('Museo'),
    url('/src/assets/fonts/MuseoSansRounded500.otf') format('opentype');
  /* src: url('/src/assets/fonts/Vision.otf') format('woff2'); */
  /* Add more src lines for different formats if needed */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSansRounded700';
  src: local('Museo'),
    url('/src/assets/fonts/MuseoSansRounded700.otf') format('opentype');
  /* src: url('/src/assets/fonts/Vision.otf') format('woff2'); */
  /* Add more src lines for different formats if needed */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSansRounded900';
  /* src: local('Museo'); */
  src: url(/src/assets/fonts/2F9F1F_4_0.eot);
  src: url(/src/assets/fonts/2F9F1F_4_0_1.eot) format("embedded-opentype"), url(/src/assets/fonts/2F9F1F_4_0.woff2) format("woff2"),
    url(/src/assets/fonts/2F9F1F_4_0.woff) format("woff"), url(/src/assets/fonts/2F9F1F_4_0.ttf) format("truetype");
  /* url('/src/assets/fonts/MuseoSansRounded900.otf') format('opentype'); */
  /* src: url('/src/assets/fonts/Vision.otf') format('woff2'); */
  /* Add more src lines for different formats if needed */
  font-weight: normal;
  font-style: normal;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'MuseoSans300';
  margin-top: 20%;
}

@media only screen and (min-width: 1200px) {
  .container {
    width: 1170px !important;
  }
}

:root {
  --iceblue: #9ec4de;
  --textBlack: #4b5256;
  --hoverBlack: #272b2d;
  --color-red: #ff0000;
}



@import './assets/css/Header.css';
@import './assets/css/HomepageTextContent.css';
@import './assets/css/HomepagetextTable.css';
@import './assets/css/footer.css';
@import './assets/css/WhoWeAreContent.css';
@import './assets/css/OurManagement.css';
@import './assets/css/Companies.css';
@import './assets/css/Accordion.css';
@import './assets/css/ComingSoon.css';
@import './assets/css/Error404.css';
@import './assets/css/Exhibition.css';
@import './assets/css/GeneralTerms.css';
.card {
  position: ralative;
  overflow: hidden;
  height: 100%;
}

.card-overlay {
  padding: 20px;
  position: absolute;
  background: rgba(0, 0, 0, .5);
  left: 0;
  top: 100%;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 350ms cubic-bezier(0, 0, 0.2, 1);
  display: flex;
  align-items: center;
  background: rgba(201, 205, 207, 0.811);
  font-size: 18px;
  text-align: center;
  font-weight: 700;
}

.card:hover .card-overlay {
  top: 0;
}

.card-overlay.left {
  left: -100%;
  top: 0;
}

.card.lkm {
  height: 100%;
  border-radius: 0;
}

.card-overlay.top {
  left: 0;
  top: -100%;
}

.card:hover .card-overlay.top {
  top: 0;
}

.imgEnixl {
  width: 100%;
  height: 100%;
}

.news {
  padding: 0 !important;
}

p.lead.newm {
  color: var(--textBlack) !important;
}

.cnm {
  margin-top: 0 !important;
}

.filter-button-group {
  text-align: center;
  margin-bottom: 25px;
}

.filter-button-group .is-checked {
  background: #9ec4de;
}

.filter-button-group .button {
  outline: 0;
  background: #fff;
  text-transform: uppercase;
  border: 1px solid #666;
  padding: 13px 15px 10px;
  font-size: 14px;
  line-height: 14px;
  margin: 0 -5px -1px 0;
  display: inline-block;
  border-radius: 0;
  font-family: 'MuseoSans300';
}

.filter-button-group .button:hover {
  background: #eee;
}

.filter-button-group .is-checked {
  background: #9ec4de;
}

.references .reference-element .ref-title h4:hover,
.references .reference-element:hover .ref-title h4:hover {
  color: #272b2d;
  font-family: "MuseoSans700";
}

.references .reference-element .ref-title h4 {
  font-size: 24px;
  cursor: default;
  transition: color .5s linear;
  text-transform: uppercase;
}

.references {
  margin: 0 auto;
  position: relative;
  width: 1138px;
  /* height: 1048px; */
}

.references .gallery-tab {
  padding: 1px;
}

.card:hover .cardhover-para p {
  font-family: "MuseoSansRounded900", sans-serif;
  font-weight: 400;
  line-height: 1.1;
  font-size: 24px;
}
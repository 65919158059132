.aboutus-sections-sub h1 {


    font-size: 40px !important;
    color: var(--textBlack);
    font-family: 'MuseoSansRounded900', sans-serif;


}

.aboutus-sections-sub h2 {
    font-size: 26px;
    font-family: 'MuseoSansRounded900', sans-serif;
}

.aboutus-sections-sub h3 {
    font-size: 24px;
    font-family: 'MuseoSansRounded900', sans-serif;
    color: var(--textBlack);
}

.accordian-para p {
    font-size: 14px;
    font-family: 'MuseoSans300', sans-serif;
    /* font-weight: 300; */
    text-align: justify;

}

.qualitylist li {
    font-size: 14px;
    color: var(--textBlack);
    font-family: 'MuseoSans300', sans-serif;
    /* font-weight: 300; */
    text-align: justify;

}

.contact-sidebar .uploads-element {
    list-style-type: none;
    padding: 0;
}

.contact-sidebar ul {
    padding: 0px 10px;
}

.aboutus-sections-sub .lead {
    margin-bottom: 23px;
    font-weight: 300 !important;
    line-height: 1.4;
    color: #4b5256 !important;
    font-size: 17px;
    font-family: 'MuseoSans700', sans-serif;

}

.sidebar-main.aboutus-sections-sub ul#sidebar-nav {
    border-radius: 0;
    border: 1px solid black;
}

.sidebar-main.aboutus-sections-sub li {
    margin-top: 0% !important;
    /* text-align: justify; */
    padding: 0;
    /* border: 0 !important; */
}

.aboutus-sections-sub .lead {
    font-size: 17px !important;
    color: #4b5256;
}

.aboutus-sections-sub p {
    font-size: 14px !important;
    line-height: 1.65 !important;
    font-family: "MuseoSans300", sans-serif !important;
    text-align: justify;
    color: var(--textBlack);
}

.aboutus-sections-sub .insoenxio {
    text-decoration: underline !important;
    color: #4b5256 !important;
}

.sidebar-main li.list-group-item.sidbr.sub-menus {
    /* padding: 7.5px 32px; */
    border-radius: 0;
    border: 0 !important;
}

.sidebar-main li>a:hover {
    background-color: #eee;
}

.sidebar-main .active-link {
    font-weight: 600;
    background: #9ec4de;
    font-family: "MuseoSans700";
    /* font-weight: 500; */
}

.sidebar-main .active-current a {
    font-weight: 600;
    font-family: "MuseoSans700" !important;
    /* font-weight: 500; */
}

.sidebar-main.aboutus-sections-sub li.list-group-item.sidbr {
    font-size: 14px !important;
    border-bottom: 1px solid black;
}

.sidebar-main.aboutus-sections-sub li,
.sidebar-main.aboutus-sections-sub a {
    border-right: 0;
    border-left: 0;

}

.sidebar-main.aboutus-sections-sub a.list-group-item.sidbr.p-0 {
    border: 0;
    padding: 7.5px 32px !important;
}

/* .sidebar-main.aboutus-sections-sub a.list-group-item.sidbr.p-0:hover {
    color: #272b2d;
} */

.list-group-item {

    /* border: transparent !important; */
}

.corporate-contact {
    font-size: 14px;
    line-height: 1.65;
}
.general-main .heading-text h1 {
    font-size: 40px !important;
    color: var(--textBlack);
    font-family: 'MuseoSansRounded900', sans-serif;
}

.general-main .heading-text h2 {
    font-size: 26px !important;
}

.uploads-fileName a {
    font-size: 14px;
    font-family: 'MuseoSans100', sans-serif;
    color: var(--textBlack);
    text-decoration: underline !important;
    font-weight: 600;

}
.uploads-fileSize {
    color: var(--textBlack);
    margin-left: 10px;
    font-size: 14px;
    font-family: 'MuseoSans100', sans-serif;
    font-weight: 600;



}
.aboutus-sections-sub  .heading-text h1 {
    font-size: 40px !important;
    color: var(--textBlack);
}

.aboutus-sections-sub  .heading-text p {
    color: var(--textBlack);
    text-align: justify;

}

.aboutus-sections-sub  .accordian-section h2 {
    margin: 23px 0 15px;
    font-size: 26px;
}
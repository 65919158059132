.whowearecontent-main .heading-text h1 {
    font-size: 40px !important;
    color: var(--textBlack);
    /* font-weight: 700; */
}

.whowearecontent-main h2 {
    font-size: 26px;
    /* font-weight: 700; */
}

.whowearecontent-main .headtext-section .lead {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 20px !important;
    line-height: 1.4;
    font-family: 'MuseoSans300' !important;
}

.whowearecontent-main .headtext-section .lead2 {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 0px !important;
    line-height: 1.4;
    font-family: 'MuseoSans300' !important;
}

.whowearecontent-main .headtext-section p {
    font-size: 14px;
    line-height: 1.65;
    color: var(--textBlack);
    margin: 0 0 11.5px;
    font-weight: 300;
    font-family: 'MuseoSans300', sans-serif !important;
    text-align: justify;


}

.whowearecontent-main .headtext-section a {
    color: var(--textBlack);
    text-decoration: underline !important;
    /* font-family: 'MuseoSans700' !important;
    font-weight: 700 !important; */
}

.whowearecontent-main .text-content2 article {
    font-size: 14px;
}

.whowearecontent-main .image-text-section .product-box {
    border: 1px solid #fff;
    min-height: 400px;
    margin-bottom: 20px;
    padding-bottom: 50px;
    position: relative;
    background: #dde1e3;
    display: block;
    text-decoration: none !important;
    cursor: pointer;
}

.whowearecontent-main .headtext-section .text-content2 .image-text-section .product-box .text .title {
    font-size: 22px !important;
    margin: 0 0 15px;
    font-weight: 700
}

.whowearecontent-main .headtext-section .product-box:hover {
    color: #272b2d;
}

.whowearecontent-main .headtext-section .text-content2 .image-text-section .product-box .text .link {
    padding-left: 15px;
    display: inline-block;
    position: absolute;
    font-family: MuseoSans-700;
    text-decoration: underline;
    left: 20px;
    bottom: 20px;
}

.whowearecontent-main .headtext-section .text-content2 .image-text-section .product-box .link::before {
    position: relative;
    width: 13px;
    top: 2px;
    font-size: 19px;
    display: inline-block;

    content: "\f0da";
    font-family: FontAwesome;

}

.whowearecontent-main .headtext-section .text-content2 .image-text-section .product-box .product-image {
    height: auto !important;
    max-width: 100% !important;
}

.whowearecontent-main .headtext-section .text-content2 .image-text-section .product-box .image {
    text-align: center;
    margin: 15px 0;
}

/*  */
.productbox {
    border: 1px solid #fff;
    min-height: 400px;
    margin-bottom: 20px;
    padding-bottom: 50px;
    position: relative;
    background: #dde1e3;
    display: block;
    text-decoration: none !important;
}

.productbox .image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
}

.image {
    display: block;
    margin-bottom: 20px;
    line-height: 1.428571429;
}

.productbox .image img {
    height: auto !important;
    max-width: 100% !important;
    width: 85%;
}

.image>img,
.image a>img {
    display: block;
    max-width: 100%;
    height: auto;
}

.productbox .text {
    margin: 0 30px 20px;
}

.productbox .title {
    font-size: 22px !important;
    margin: 0 0 15px;
}

.productbox .link {
    padding-left: 15px;
    display: inline-block;
    position: absolute;
    font-family: 'MuseoSans700';
    text-decoration: underline;
    left: 20px;
    bottom: 20px;
}

.productbox .link:before {
    position: relative;
    width: 13px;
    top: 2px;
    font-size: 19px;
    display: inline-block;
}

.productbox .link:before {
    content: "\f0da";
    font-family: FontAwesome;
}

/*  */